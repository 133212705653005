import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, catchError, filter, map, of } from 'rxjs';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { PageLayout } from '../../layout/layout.enum';
import { Page, PageInfo } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private pageLayout = new BehaviorSubject<PageInfo<Page<any>> | undefined>(undefined);
  private lastState!: PageInfo<Page<any>>;

  constructor(private router: Router) {}

  get pageLayout$(): Observable<PageInfo<Page<any>> | undefined> {
    return this.pageLayout.asObservable();
  }

  setPageLayout(state: PageInfo<Page<any>>) {
    this.pageLayout.next(state);
  }

  resolveLayoutForCurrentRoute(): Observable<PageInfo<Page<any>> | undefined> {
    return this.router.events.pipe(
      filter((event: any) => this.filterEvents(event)), // event is ActivationEnd ensures that event is of type ActivationEnd
      map((event) => this.determineLayout(event)), // In this switchMap, we check if the page is a POH page or not
      catchError((error) => {
        console.error('Error determining layout', error);
        return of({ id: PageLayout.Default, data: null });
      }),
    );
  }

  private filterEvents(event: any): boolean {
    if (event.type == 14 && event instanceof ActivationEnd) {
      return true;
    }

    if (event.type == 1 && event instanceof NavigationEnd) {
      return true;
    }

    return false;
  }

  private determineLayout(event: NavigationEnd | ActivationEnd): PageInfo<Page<any>> | undefined {
    return this.getLayoutFromEvent(event);
  }

  private getLayoutFromEvent(event: NavigationEnd | ActivationEnd): PageInfo<Page<any>> | undefined {
    if (event instanceof ActivationEnd) {
      this.setActivationLayout(event);
      return undefined;
    } else {
      return this.lastState;
    }
  }

  private setActivationLayout(event: ActivationEnd): void {
    let id = PageLayout.Default;
    let data = null;
    if (event.snapshot.data.layout) {
      id = event.snapshot.data.layout;
    } else if (event.snapshot.data.section?.layout) {
      id = event.snapshot.data.section.layout;
    } else if (event.snapshot.data.page?.pageType) {
      id = event.snapshot.data.page.pageType;
      data = event.snapshot.data.page;
    }

    this.lastState = { id: id, data: data };
  }
}
